import { CHAINID_MAPPING, TOKEN_MAPPING, VESTING_ADDRESS_MAPPING } from './types'
export const TOKENS_PATH = '/tokens'
export const AIRDROP_PATH = '/airdrop'

export const SOCIAL = {
  // Github: GITHUB_PATH,
  // Twitter: TWITTER_PATH,
  // Telegram: TELEGRAM_PATH,
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const DEFAULT_AUCTION_INDEX = 0

export const PROVIDER_POLLING_INTERVAL = 12000
export const STAKING_REWARD_AT = 1652522400000
export const STAKING_FINISH_AT = 1650189600000

// token: 0x273Bd60699F9aeD99a3a81795BCE3FEE29f963E4
export const VESTING_CONTRACT_ADDRESS: VESTING_ADDRESS_MAPPING = {
  56: {
    // TEAM: '0xA48558DA35dFEd41363B5895a9700e4c6d11D375',
    // MARKETING: '0x58B5f1EDb7200F84f8678AAf0e39a076E9271D01',
    // ECOSYSTEM: '0x275CFABf5C09B4CBE033916087c0b9365cAd6883',
    // ADVISOR: '0x85FA84D5451f38eEd738305e91D34479C7A245B8',
    PRIVATE: '0x5AF5f337a2c695B27C2ED5B9F26eBfF68c2Ef836'
  },
  88: {
    TEAM: '0xA48558DA35dFEd41363B5895a9700e4c6d11D375',
    MARKETING: '0x58B5f1EDb7200F84f8678AAf0e39a076E9271D01',
    ECOSYSTEM: '0x275CFABf5C09B4CBE033916087c0b9365cAd6883',
    ADVISOR: '0x85FA84D5451f38eEd738305e91D34479C7A245B8',
    PRIVATE: '0xa6A01ab12D8425b9ed1CE16cd20B0dA9B540d83B'
  },
  89: {
    // TEAM: '0xba15c7B1DE619A92BF2d947bcbADfD19d51760d2',
    // MARKETING: '0xba15c7B1DE619A92BF2d947bcbADfD19d51760d2',
    // ECOSYSTEM: '0xba15c7B1DE619A92BF2d947bcbADfD19d51760d2',
    // ADVISOR: '0xba15c7B1DE619A92BF2d947bcbADfD19d51760d2',
    PRIVATE: '0xba15c7B1DE619A92BF2d947bcbADfD19d51760d2'
  },
}

export const STAKING_CONTRACT_ADDRESS: CHAINID_MAPPING = {
  56: '0xE101C3D7a6aF4699C8cDc7B2e212e093b976708f',
  88: '0x0000000000000000000000000000000000000000',
  89: '0x0B1a80437d6F39f1B6EC9997407A4302D2889258',
}

export const TOKEN1_STAKE: TOKEN_MAPPING = {
  56: {
    ADDRESS: '0x06597FFaFD82E66ECeD9209d539032571ABD50d9',
    SYMBOL: 'MOL',
    NAME: 'MOL',
    MIN: '12000',
    
  },
  88: {
    ADDRESS: '0x0000000000000000000000000000000000000000',
    SYMBOL: '',
    NAME: '',
    MIN: "0"
  },
  89: {
    ADDRESS: '0x7Df9AfB35C00a9A57c7BCfBc771e9dD97822e2c7',
    SYMBOL: 'CBT',
    NAME: 'CBT',
    MIN: "0"
  },
}

export const TOKEN2_STAKE: TOKEN_MAPPING = {
  56: {
    ADDRESS: '0xdf3df1CAaC971EBEA3D1E606Bd7fb4A12b5adA96',
    SYMBOL: 'Cake-LP (MOL-BUSD)',
    NAME: 'Cake-LP (MOL-BUSD)',
    MIN: "0"
  },
  88: {
    ADDRESS: '0x0000000000000000000000000000000000000000',
    SYMBOL: '',
    NAME: '',
    MIN: "0"
  },
  89: {
    ADDRESS: '0x0000000000000000000000000000000000000000',
    SYMBOL: 'BUSD',
    NAME: 'BUSD',
    MIN: "0"
  },
}

export const RPC_URLS: CHAINID_MAPPING = {
  56: 'https://bsc-dataseed.binance.org',
  88: 'https://rpc.tomochain.com',
  89: 'https://testnet.tomochain.com',
}

export const TX_SCANERS: CHAINID_MAPPING = {
  56: 'https://bscscan.com/tx/',
  88: 'https://tomoscan.io/tx/',
  89: 'https://testnet.tomoscan.io/tx/',
}

export const NAME = 'MOL'

export const HEADER_LINKS = {
  VESTING: TOKENS_PATH,
  // "AIRDROP": AIRDROP_PATH,
  // Whitepaper: "/paper.pdf",
  // Social: SOCIAL,
}

export const MOBILE_HEADER_LINKS = {
  VESTING: TOKENS_PATH,
  // Airdrop: AIRDROP_PATH,
  // Whitepaper: "/paper.pdf",
}
